import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import antd from "./lib/antd";
import common from "./lib/common";
import axios from "axios";
import http from "../src/lib/axios";

Vue.config.productionTip = false;

Vue.use(antd);

Vue.prototype.$axios = axios;
Vue.prototype.$http = http;
Vue.prototype.$common = common;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
