//封装公共代码
const common = {};

// pt-online-schema-change生成
common.ptSchemaChange = ({ alter, host, port, sql, dbName }, tableName) => {
  let head = "pt-online-schema-change ",
    H = "--host=",
    uroot = "-uroot ",
    P = "-P",
    p = "-p$PWD ",
    A = "--alter ",
    D = "D=",
    t = "t=",
    tail = "--execute --charset=utf8 --no-check-alter",
    ptText = "";
  sql = sql.replace(/\"/g, "'"); // 双引号替换成单引号
  if (alter) {
    if (port === 3306) {
      ptText =
        head +
        H +
        host +
        " " +
        uroot +
        p +
        A +
        '"' +
        sql +
        '" ' +
        D +
        dbName +
        "," +
        t +
        "'" +
        tableName +
        "' " +
        tail;
    } else {
      ptText =
        head +
        H +
        host +
        " " +
        uroot +
        P +
        port +
        " " +
        p +
        A +
        '"' +
        sql +
        '" ' +
        D +
        dbName +
        "," +
        t +
        "'" +
        tableName +
        "' " +
        tail;
    }
  } else {
    if (port === 3306) {
      ptText =
        head +
        H +
        host +
        " " +
        uroot +
        p +
        '"' +
        sql +
        '" ' +
        D +
        dbName +
        "," +
        t +
        "'" +
        tableName +
        "' " +
        tail;
    } else {
      ptText =
        head +
        H +
        host +
        " " +
        uroot +
        P +
        port +
        " " +
        p +
        '"' +
        sql +
        '" ' +
        D +
        dbName +
        "," +
        t +
        "'" +
        tableName +
        "' " +
        tail;
    }
  }
  return ptText + "\n";
};

// 复制数据库密码信息
common.copyInfo = (parameter, dbName) => {
  let headBorder = "";
  let content = ""; // copy内容
  let paramsConent = []; // 内容长度
  let heatLengt = []; // 标题长度
  for (let item in parameter) {
    let str = parameter[item].toString();
    paramsConent.push(str.length);
    heatLengt.push(item.length);
  }
  paramsConent = paramsConent.sort((a, b) => b - a);
  heatLengt = heatLengt.sort((a, b) => b - a);
  // 表头绘制
  if (dbName.length < paramsConent[0] + heatLengt[0]) {
    let dValue = paramsConent[0] + heatLengt[0] + 2 - dbName.length;
    for (let j = 0; j < dValue; j++) {
      let blankSpace = " ";
      dbName += blankSpace;
    }
    // 边框绘制
    for (let y = 0; y < paramsConent[0] + heatLengt[0] + 4; y++) {
      let HLine = "-";
      headBorder += HLine;
    }
    headBorder = "+" + headBorder + "+";
  }
  // 表格内容绘制
  for (let i in parameter) {
    let heat = i;
    parameter[i] = parameter[i].toString();
    if (parameter[i].length < paramsConent[0]) {
      let dValue = paramsConent[0] - parameter[i].length;
      for (let k = 0; k < dValue; k++) {
        let blankSpace = " ";
        parameter[i] += blankSpace;
      }
    }
    // 绘制标题
    if (i.length < heatLengt[0]) {
      let dValue = heatLengt[0] - i.length;
      for (let k = 0; k < dValue; k++) {
        let blankSpace = " ";
        heat += blankSpace;
      }
    }
    if (parameter[i].trim()) {
      content += "| " + heat + ": " + parameter[i] + " |" + "\n";
    }
  }
  return (
    headBorder +
    "\n" +
    "| " +
    dbName +
    " |" +
    "\n" +
    headBorder +
    "\n" +
    content +
    headBorder
  );
};

// 复制数据库坐标信息
common.copyCoordinate = (parameter, dbName) => {
  let url =
      "jdbc:mysql://" +
      parameter.host +
      ":" +
      parameter.port +
      "/" +
      dbName +
      "?allowMultiQueries=true&useUnicode=true&useSSL=false&characterEncoding=UTF-8&serverTimezone=Asia/Shanghai",
    driver = "driverClassName: com.mysql.cj.jdbc.Driver",
    blankSpace = "   ";
  return (
    dbName +
    ":" +
    "\n" +
    blankSpace +
    "url: " +
    '"' +
    url +
    '"' +
    "\n" +
    blankSpace +
    "username: " +
    '"' +
    parameter.userName +
    '"' +
    "\n" +
    blankSpace +
    "password: " +
    '"' +
    '"' +
    "\n" +
    blankSpace +
    "connectionProperties: x=" +
    parameter.x +
    ";y=" +
    parameter.y
  ); // + "\n" + blankSpace + driver;
};

// 发布日志表单数据处理
common.gitLog = (list) => {
  let timeList = []; // 提交时间
  let typeList = []; // 提交前缀
  let newList = []; // 新数组
  let gitLogMessage = []; // 输出日志表单
  list.forEach((item) => {
    timeList.push(item.commitDate);
    typeList.push(item.type);
  });
  typeList = common.unique(typeList);
  typeList.forEach((i) => {
    gitLogMessage.push({
      //显示日志type赋值
      type: i,
      message: [],
    });
  });
  timeList = common.unique(timeList);
  timeList = timeList.sort((a, b) => b - a);
  list.forEach((y) => {
    // 时间排序
    for (let k of timeList) {
      if (k === y.commitDate) {
        newList.push(y);
      }
    }
  });
  newList.forEach((j) => {
    for (let menu in gitLogMessage) {
      if (j.type === gitLogMessage[menu].type) {
        //显示日志message赋值
        gitLogMessage[menu].message.push({
          time: j.commitDate,
          content: j.information,
        });
      }
    }
  });
  return gitLogMessage;
};
common.dayFormat = (day) => {
  let date = new Date(day).toLocaleDateString().replace(/\//g, "-");
  date = date.split("-");
  date.forEach((element, index) => {
    if (element * 1 < 10) {
      date[index] = 0 + element;
    }
  });
  date = date.join("-");
  return date;
};

//时间格式：yyyy-MM-dd HH:mm:ss
common.transformTime = function (time) {
  var time = new Date(time);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  function add0(m) {
    return m < 10 ? "0" + m : m;
  }
  return (
    y +
    "-" +
    add0(m) +
    "-" +
    add0(d) +
    " " +
    add0(h) +
    ":" +
    add0(mm) +
    ":" +
    add0(s)
  );
};

//Es6数组去重
common.unique = function (arr) {
  var x = new Set(arr);
  return [...x];
};

// 深度拷贝
common.deepClone = (source) => {
  const targetObj = source.constructor === Array ? [] : {}; // 判断复制的目标是数组还是对象
  for (const keys in source) {
    // 遍历目标
    if (source.hasOwnProperty(keys)) {
      if (source[keys] && typeof source[keys] === "object") {
        // 如果值是对象，就递归一下
        targetObj[keys] = source[keys].constructor === Array ? [] : {};
        targetObj[keys] = common.deepClone(source[keys]);
      } else {
        // 如果不是，就直接赋值
        targetObj[keys] = source[keys];
      }
    }
  }
  return targetObj;
};

// 随机生成数字+大小写字母
common.randomStr = function (randomFlag, min, max) {
  // randomFlag：是否任意长度
  // min：任意长度最小位[固定位数]
  // max：任意长度最大位
  //生成3-32位随机串：randomStr(true, 3, 32)
  //生成43位随机串：randomStr(false, 43)
  let str = "",
    range = min,
    index = null,
    arr = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

  if (randomFlag) {
    // 随机产生
    range = Math.round(Math.random() * (max - min)) + min;
  }

  for (let i = 0; i < range; i++) {
    index = Math.round(Math.random() * (arr.length - 1));
    str += arr[index];
  }
  return str;
};

//input只能输入数字和一个小数点
common.numberCheck = (num) => {
  var str = num;
  var len1 = str.substr(0, 1);
  var len2 = str.substr(1, 1);
  //如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 == 0 && len2 != ".") {
    str = str.substr(1, 1);
  }
  //第一位不能是.
  if (len1 == ".") {
    str = "";
  }
  //限制只能输入一个小数点
  if (str.indexOf(".") != -1) {
    var str_ = str.substr(str.indexOf(".") + 1);
    if (str_.indexOf(".") != -1) {
      str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
    }
  }
  //正则替换，保留数字和小数点
  str = str.replace(/[^\d^\.]+/g, "");
  //如果需要保留小数点后两位，则用下面公式
  // str = str.replace(/\.\d\d$/,'')
  return str;
};

//防止多次点击
common.promiseDownload = (href) => {
  let p = new Promise((resolve, rejects) => {
    let a = false;
    window.location.href = href;
    resolve(a);
  });
  return p;
};

// 创建cookie
common.setCookie = (c_name, value, expiredays) => {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie =
    c_name +
    "=" +
    escape(value) +
    (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
};

// 读取cookie
common.getCookie = (c_name) => {
  if (document.cookie.length > 0) {
    //先查询cookie是否为空，为空就return ""
    let c_start = document.cookie.indexOf(c_name + "="); //通过String对象的indexOf()来检查这个cookie是否存在，不存在就为 -1
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1; //最后这个+1其实就是表示"="号啦，这样就获取到了cookie值的开始位置
      let c_end = document.cookie.indexOf(";", c_start); //其实我刚看见indexOf()第二个参数的时候猛然有点晕，后来想起来表示指定的开始索引的位置...这句是为了得到值的结束位置。因为需要考虑是否是最后一项，所以通过";"号是否存在来判断
      if (c_end == -1) c_end = document.cookie.length;
      return unescape(document.cookie.substring(c_start, c_end)); //通过substring()得到了值。想了解unescape()得先知道escape()是做什么的，都是很重要的基础，想了解的可以搜索下，在文章结尾处也会进行讲解cookie编码细节
    }
  }
  return "";
};

// 插入到光标位置
common.insertInputTxt = (insertText, id, textarea) => {
  let text = insertText;
  let areaField = document.getElementById(id); // 拿到目标标签
  // IE浏览器
  if (document.selection) {
    areaField.focus();
    let sel = document.selection.createRange();
    sel.text = text;
  } else if (areaField.selectionStart || areaField.selectionStart === 0) {
    // 谷歌 Firefox 等
    let startPos = areaField.selectionStart;
    let endPos = areaField.selectionEnd;
    let restoreTop = areaField.scrollTop; // 获取滚动条高度
    //  textarea 是v-model的值
    // text 是 选择的要插入的值
    textarea =
      textarea.substring(0, startPos) +
      text +
      textarea.substring(endPos, textarea.length);
    if (restoreTop > 0) {
      areaField.scrollTop = restoreTop;
    }
    areaField.focus();
    areaField.selectionStart = startPos + text.length;
    areaField.selectionEnd = startPos + text.length;
  } else {
    textarea += text;
    areaField.focus();
  }
  return textarea;
};

// 测试
common.a = function () {
  console.log("a测试测试");
  return 888;
};

// 编译测试数据
common.command = () => {
  let data = [
    { key: "1", status: "info", data: "" },
    {
      key: "1",
      step: 1,
      data: "14:43:49 \u6570\u636e\u51c6\u5907...        ",
    },
    { key: "1", step: 1, data: "\u5b8c\u6210\r\n" },
    {
      key: "1",
      step: 3,
      data: "14:45:39 \u6267\u884c\u53d1\u5e03...        ",
    },
    { key: "1", status: "info", data: "" },
    { key: "1", step: 3, data: "\u5b8c\u6210\r\n" },
    {
      key: "1",
      step: 4,
      data: "14:45:40 \u53d1\u5e03\u540e\u4efb\u52a1...       \r\n",
    },
    {
      key: "1",
      status: "info",
      data: "huixiao-web                      RUNNING   pid 21066, uptime 15:20:07\n",
    },
    { key: "1", status: "info", data: "" },
    {
      key: "1",
      step: 5,
      data: "\r\n14:45:40 ** \u53d1\u5e03\u6210\u529f **",
    },
    { key: "2", status: "info", data: "" },
    {
      key: "2",
      step: 1,
      data: "14:43:49 \u6570\u636e\u51c6\u5907...        ",
    },
    { key: "2", step: 1, data: "\u5b8c\u6210\r\n" },
    {
      key: "2",
      step: 3,
      data: "14:45:39 \u6267\u884c\u53d1\u5e03...        ",
    },
    { key: "2", status: "info", data: "" },
    { key: "2", step: 3, data: "\u5b8c\u6210\r\n" },
    {
      key: "2",
      step: 4,
      data: "14:45:40 \u53d1\u5e03\u540e\u4efb\u52a1...       \r\n",
    },
    {
      key: "2",
      status: "info",
      data: "huixiao-web                      RUNNING   pid 12345, uptime 15:20:07\n",
    },
    { key: "2", status: "info", data: "" },
    {
      key: "2",
      step: 5,
      data: "\r\n14:45:40 ** \u53d1\u5e03\u6210\u529f **",
    },
    {
      key: "local",
      step: 1,
      data: "\u5b8c\u6210\r\n14:35:10 \u53d1\u5e03\u51c6\u5907...        ",
    },
    { key: "local", step: 1, data: "\u5b8c\u6210\r\n" },
    {
      key: "local",
      step: 3,
      data: "14:35:10 \u6267\u884c\u68c0\u51fa...        ",
    },
    { key: "local", step: 3, data: "\u5b8c\u6210\r\n" },
    {
      key: "local",
      step: 4,
      data: "14:35:10 \u68c0\u51fa\u540e\u4efb\u52a1...\r\n",
    },
  ];
  let a = [
    '{"key": "local", "step": 6, "data": "\\u5b8c\\u6210"}',
    '{"key": "local", "step": 5, "data": "\\r\\n14:35:38 \\u6267\\u884c\\u6253\\u5305... "}',
    '{"key": "local", "status": "info", "data": "[INFO] ------------------------------------------------------------------------\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Finished at: 2020-10-17T14:35:38+08:00\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Total time: 26.732 s\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] ------------------------------------------------------------------------\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] BUILD SUCCESS\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] ------------------------------------------------------------------------\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] huixiao-analysis ................................... SUCCESS [ 2.457 s]\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] huixiao-web ........................................ SUCCESS [ 21.524 s]\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] huixiao ............................................ SUCCESS [ 0.523 s]\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] huixiao-common Maven Webapp ........................ SUCCESS [ 1.999 s]\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Reactor Summary for huixiao 1.0-SNAPSHOT:\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] ------------------------------------------------------------------------\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Installing /data/spug/spug_api/repos/2_8_20201017143510/huixiao-analysis/pom.xml to /root/.m2/repository/com/huiyu/huixiao/huixiao-analysis/1.0-SNAPSHOT/huixiao-analysis-1.0-SNAPSHOT.pom\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Installing /data/spug/spug_api/repos/2_8_20201017143510/huixiao-analysis/target/huixiao-analysis-1.0-SNAPSHOT.jar to /root/.m2/repository/com/huiyu/huixiao/huixiao-analysis/1.0-SNAPSHOT/huixiao-analysis-1.0-SNAPSHOT.jar\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-install-plugin:2.5.2:install (default-install) @ huixiao-analysis ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- spring-boot-maven-plugin:2.0.2.RELEASE:repackage (default) @ huixiao-analysis ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Building jar: /data/spug/spug_api/repos/2_8_20201017143510/huixiao-analysis/target/huixiao-analysis-1.0-SNAPSHOT.jar\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-jar-plugin:3.0.2:jar (default-jar) @ huixiao-analysis ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Tests are skipped.\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-surefire-plugin:2.21.0:test (default-test) @ huixiao-analysis ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Compiling 1 source file to /data/spug/spug_api/repos/2_8_20201017143510/huixiao-analysis/target/test-classes\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Changes detected - recompiling the module!\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-compiler-plugin:3.7.0:testCompile (default-testCompile) @ huixiao-analysis ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] skip non existing resourceDirectory /data/spug/spug_api/repos/2_8_20201017143510/huixiao-analysis/src/test/resources\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Using \'UTF-8\' encoding to copy filtered resources.\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-resources-plugin:3.0.1:testResources (default-testResources) @ huixiao-analysis ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] /data/spug/spug_api/repos/2_8_20201017143510/huixiao-analysis/src/main/java/com/huiyu/huixiao/analysis/config/JedisCacheManager.java: Recompile with -Xlint:unchecked for details.\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] /data/spug/spug_api/repos/2_8_20201017143510/huixiao-analysis/src/main/java/com/huiyu/huixiao/analysis/config/JedisCacheManager.java: /data/spug/spug_api/repos/2_8_20201017143510/huixiao-analysis/src/main/java/com/huiyu/huixiao/analysis/config/JedisCacheManager.java uses unchecked or unsafe operations.\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Compiling 34 source files to /data/spug/spug_api/repos/2_8_20201017143510/huixiao-analysis/target/classes\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Changes detected - recompiling the module!\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-compiler-plugin:3.7.0:compile (default-compile) @ huixiao-analysis ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Copying 4 resources\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Copying 2 resources\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Using \'UTF-8\' encoding to copy filtered resources.\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-resources-plugin:3.0.1:resources (default-resources) @ huixiao-analysis ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-clean-plugin:3.0.0:clean (default-clean) @ huixiao-analysis ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --------------------------------[ jar ]---------------------------------\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Building huixiao-analysis 1.0-SNAPSHOT [4/4]\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] -----------------< com.huiyu.huixiao:huixiao-analysis >-----------------\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Installing /data/spug/spug_api/repos/2_8_20201017143510/huixiao-web/pom.xml to /root/.m2/repository/com/huiyu/huixiao/huixiao-web/1.0-SNAPSHOT/huixiao-web-1.0-SNAPSHOT.pom\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Installing /data/spug/spug_api/repos/2_8_20201017143510/huixiao-web/target/huixiao-web-1.0-SNAPSHOT.jar to /root/.m2/repository/com/huiyu/huixiao/huixiao-web/1.0-SNAPSHOT/huixiao-web-1.0-SNAPSHOT.jar\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-install-plugin:2.5.2:install (default-install) @ huixiao-web ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- spring-boot-maven-plugin:2.0.2.RELEASE:repackage (default) @ huixiao-web ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Building jar: /data/spug/spug_api/repos/2_8_20201017143510/huixiao-web/target/huixiao-web-1.0-SNAPSHOT.jar\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-jar-plugin:3.0.2:jar (default-jar) @ huixiao-web ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Tests are skipped.\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-surefire-plugin:2.21.0:test (default-test) @ huixiao-web ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Compiling 13 source files to /data/spug/spug_api/repos/2_8_20201017143510/huixiao-web/target/test-classes\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Changes detected - recompiling the module!\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-compiler-plugin:3.7.0:testCompile (default-testCompile) @ huixiao-web ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] skip non existing resourceDirectory /data/spug/spug_api/repos/2_8_20201017143510/huixiao-web/src/test/resources\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Using \'UTF-8\' encoding to copy filtered resources.\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-resources-plugin:3.0.1:testResources (default-testResources) @ huixiao-web ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] /data/spug/spug_api/repos/2_8_20201017143510/huixiao-web/src/main/java/com/huiyu/huixiao/config/JedisCacheManager.java: Recompile with -Xlint:unchecked for details.\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] /data/spug/spug_api/repos/2_8_20201017143510/huixiao-web/src/main/java/com/huiyu/huixiao/config/JedisCacheManager.java: Some input files use unchecked or unsafe operations.\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] /data/spug/spug_api/repos/2_8_20201017143510/huixiao-web/src/main/java/com/huiyu/huixiao/utils/ExcelUtil.java: Recompile with -Xlint:deprecation for details.\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] /data/spug/spug_api/repos/2_8_20201017143510/huixiao-web/src/main/java/com/huiyu/huixiao/utils/ExcelUtil.java: Some input files use or override a deprecated API.\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Compiling 658 source files to /data/spug/spug_api/repos/2_8_20201017143510/huixiao-web/target/classes\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Changes detected - recompiling the module!\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-compiler-plugin:3.7.0:compile (default-compile) @ huixiao-web ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Copying 26 resources\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Copying 52 resources\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Using \'UTF-8\' encoding to copy filtered resources.\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-resources-plugin:3.0.1:resources (default-resources) @ huixiao-web ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-clean-plugin:3.0.0:clean (default-clean) @ huixiao-web ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "Progress (1): 1.0 kB\\r \\rDownloaded from huiyu-group: http://maven.huiyu.org.cn/repository/huiyu-group/com/huiyu/data/report/data-report-api/1.0.0-SNAPSHOT/maven-metadata.xml (1.0 kB at 22 kB/s)\\n"}',
    '{"key": "local", "status": "info", "data": "Downloading from huiyu-group: http://maven.huiyu.org.cn/repository/huiyu-group/com/huiyu/data/report/data-report-api/1.0.0-SNAPSHOT/maven-metadata.xml\\n"}',
    '{"key": "local", "status": "info", "data": "Progress (1): 996 B\\r \\rDownloaded from huiyu-group: http://maven.huiyu.org.cn/repository/huiyu-group/com/huiyu/common/huiyu-common/1.0.1-SNAPSHOT/maven-metadata.xml (996 B at 22 kB/s)\\n"}',
    '{"key": "local", "status": "info", "data": "Downloading from huiyu-group: http://maven.huiyu.org.cn/repository/huiyu-group/com/huiyu/common/huiyu-common/1.0.1-SNAPSHOT/maven-metadata.xml\\n"}',
    '{"key": "local", "status": "info", "data": "Progress (1): 1.0 kB\\r \\rDownloaded from huiyu-group: http://maven.huiyu.org.cn/repository/huiyu-group/io/github/happytimor/mybatis-helper-core/1.0.7-SNAPSHOT/maven-metadata.xml (1.0 kB at 22 kB/s)\\n"}',
    '{"key": "local", "status": "info", "data": "Downloading from huiyu-group: http://maven.huiyu.org.cn/repository/huiyu-group/io/github/happytimor/mybatis-helper-core/1.0.7-SNAPSHOT/maven-metadata.xml\\n"}',
    '{"key": "local", "status": "info", "data": "Progress (1): 996 B\\r \\rDownloaded from huiyu-group: http://maven.huiyu.org.cn/repository/huiyu-group/com/huiyu/db/db-password-callback/1.0.2-SNAPSHOT/maven-metadata.xml (996 B at 7.8 kB/s)\\n"}',
    '{"key": "local", "status": "info", "data": "Downloading from huiyu-group: http://maven.huiyu.org.cn/repository/huiyu-group/com/huiyu/db/db-password-callback/1.0.2-SNAPSHOT/maven-metadata.xml\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --------------------------------[ jar ]---------------------------------\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Building huixiao-web 1.0-SNAPSHOT [3/4]\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] -------------------< com.huiyu.huixiao:huixiao-web >--------------------\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Installing /data/spug/spug_api/repos/2_8_20201017143510/pom.xml to /root/.m2/repository/com/huiyu/huixiao/huixiao/1.0-SNAPSHOT/huixiao-1.0-SNAPSHOT.pom\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-install-plugin:2.5.2:install (default-install) @ huixiao ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- spring-boot-maven-plugin:2.0.2.RELEASE:repackage (default) @ huixiao ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-clean-plugin:3.0.0:clean (default-clean) @ huixiao ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --------------------------------[ pom ]---------------------------------\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Building huixiao 1.0-SNAPSHOT [2/4]\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] ---------------------< com.huiyu.huixiao:huixiao >----------------------\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Installing /data/spug/spug_api/repos/2_8_20201017143510/huixiao-common/pom.xml to /root/.m2/repository/com/huiyu/huixiao/huixiao-common/1.0-SNAPSHOT/huixiao-common-1.0-SNAPSHOT.pom\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Installing /data/spug/spug_api/repos/2_8_20201017143510/huixiao-common/target/huixiao-common-1.0-SNAPSHOT.jar to /root/.m2/repository/com/huiyu/huixiao/huixiao-common/1.0-SNAPSHOT/huixiao-common-1.0-SNAPSHOT.jar\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-install-plugin:2.4:install (default-install) @ huixiao-common ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Building jar: /data/spug/spug_api/repos/2_8_20201017143510/huixiao-common/target/huixiao-common-1.0-SNAPSHOT.jar\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-jar-plugin:2.4:jar (default-jar) @ huixiao-common ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] No tests to run.\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-surefire-plugin:2.12.4:test (default-test) @ huixiao-common ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] No sources to compile\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-compiler-plugin:3.1:testCompile (default-testCompile) @ huixiao-common ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] skip non existing resourceDirectory /data/spug/spug_api/repos/2_8_20201017143510/huixiao-common/src/test/resources\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Using \'UTF-8\' encoding to copy filtered resources.\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-resources-plugin:2.6:testResources (default-testResources) @ huixiao-common ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Compiling 3 source files to /data/spug/spug_api/repos/2_8_20201017143510/huixiao-common/target/classes\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Changes detected - recompiling the module!\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-compiler-plugin:3.1:compile (default-compile) @ huixiao-common ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] skip non existing resourceDirectory /data/spug/spug_api/repos/2_8_20201017143510/huixiao-common/src/main/resources\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Using \'UTF-8\' encoding to copy filtered resources.\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-resources-plugin:2.6:resources (default-resources) @ huixiao-common ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --- maven-clean-plugin:2.5:clean (default-clean) @ huixiao-common ---\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] --------------------------------[ jar ]---------------------------------\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Building huixiao-common Maven Webapp 1.0-SNAPSHOT [1/4]\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] ------------------< com.huiyu.huixiao:huixiao-common >------------------\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] huixiao-analysis [jar]\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] huixiao-web [jar]\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] huixiao [pom]\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] huixiao-common Maven Webapp [jar]\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Reactor Build Order:\\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] ------------------------------------------------------------------------\\n"}',
    '{"key": "local", "status": "info", "data": "[WARNING] \\n"}',
    '{"key": "local", "status": "info", "data": "[WARNING] For this reason, future Maven versions might no longer support building such malformed projects.\\n"}',
    '{"key": "local", "status": "info", "data": "[WARNING] \\n"}',
    '{"key": "local", "status": "info", "data": "[WARNING] It is highly recommended to fix these problems because they threaten the stability of your build.\\n"}',
    '{"key": "local", "status": "info", "data": "[WARNING] \\n"}',
    '{"key": "local", "status": "info", "data": "[WARNING] \'dependencies.dependency.(groupId:artifactId:type:classifier)\' must be unique: org.springframework.boot:spring-boot-starter-data-redis:jar -> version (?) vs 2.1.5.RELEASE @ line 131, column 21\\n"}',
    '{"key": "local", "status": "info", "data": "[WARNING] Some problems were encountered while building the effective model for com.huiyu.huixiao:huixiao-analysis:jar:1.0-SNAPSHOT\\n"}',
    '{"key": "local", "status": "info", "data": "[WARNING] \\n"}',
    '{"key": "local", "status": "info", "data": "[INFO] Scanning for projects...\\n"}',
    '{"key": "local", "step": 4, "data": "14:35:10 \\u68c0\\u51fa\\u540e\\u4efb\\u52a1...\\r\\n"}',
    '{"key": "local", "step": 3, "data": "\\u5b8c\\u6210\\r\\n"}',
    '{"key": "local", "step": 3, "data": "14:35:10 \\u6267\\u884c\\u68c0\\u51fa... "}',
    '{"key": "local", "step": 1, "data": "\\u5b8c\\u6210\\r\\n"}',
    '{"key": "local", "step": 1, "data": "\\u5b8c\\u6210\\r\\n14:35:10 \\u53d1\\u5e03\\u51c6\\u5907... "}',
  ];
  a.reverse();
  for (let i in a) {
    a[i] = JSON.parse(a[i]);
  }
  let b = [...data, ...a];
  return b;
};

export default common;
