import Vue from "vue";
import VueRouter from "vue-router";

//cv以下代码解决路由地址重复的报错问题(一劳永逸)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/incrSync",
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "/incrSync",
        name: "incrSync",
        component: () => import("../components/incrSync.vue"),
      },
      {
        path: "/fullSync",
        name: "fullSync",
        redirect: "/fullSync/noDelayTask",
        component: () => import("../components/fullSync.vue"),
        children: [
          {
            path: "/fullSync/noDelayTask",
            name: "noDelayTask",
            component: () => import("../components/fullSyncTemplate.vue"),
          },
          {
            path: "/fullSync/scheduledTask",
            name: "scheduledTask",
            component: () => import("../components/fullSyncTemplate.vue"),
          },
          {
            path: "/fullSync/scheduledSubTask",
            name: "scheduledSubTask",
            component: () => import("../components/fullSyncTemplate.vue"),
          },
        ],
      },
      {
        path: "/syncRuleList",
        name: "syncRuleList",
        component: () => import("../components/syncRuleList.vue"),
      },
      {
        path: "/dataSourceList",
        name: "dataSourceList",
        component: () => import("../components/dataSourceList.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
