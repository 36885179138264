<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <router-view v-if="isRouterAlive" />
    </div>
  </a-config-provider>
</template>
<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";

moment.locale("zh-cn");
export default {
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true, //控制视图是否显示的变量
      zh_CN,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true; //再打开
      });
    },
  },
};
</script>
<style lang="scss">
html,
body,
div,
ul,
b,
li,
i,
em,
h1,
h2,
h3,
span,
a {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}

.searchCondition {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .searchConditionItem {
    display: flex;
    margin-right: 20px;
    margin-bottom: 20px;
    align-items: center;
    span {
      white-space: nowrap;
    }
    .ant-input-affix-wrapper {
      width: 240px;

      .ant-input {
        width: 100%;
      }
    }
    .ant-input {
      width: 240px;
    }

    .ant-select {
      width: 240px;
    }
    .ant-calendar-picker-input {
      width: 100% !important;
    }
  }
}

.searchButton {
  button {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

.ant-btn-link {
  padding: 0 !important;
  height: 14px !important;
  line-height: 14px !important;
  &[disabled] {
    color: #00000040 !important;
  }
}
a[disabled] {
  cursor: not-allowed !important;
  pointer-events: none !important;
  color: #00000040 !important;
}
.ant-table-body {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ededed;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #7c7c7c;
  }
}
.ant-popover-message-title {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.reminder {
  font-weight: 500;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e8e8e8;
  color: black;
}
.dark-row {
  background: #e1f5ff;
}
.light-row {
  background: #ffffff;
}
</style>
